import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";

import { setActiveTab } from "../../redux/actions";

//Import Images
import logo from "../../assets/images/logo.png";
import { SourceURL } from "../../constants/conPublicLink.js";
import defaultImg from "../../assets/images/default.png";
import logoutImg from "../../assets/images/logout.png";

function LeftSidebarMenu(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = props.activeTab;
  // console.log('activeTab',activeTab);
  const notificationNB = props.notificationNB;
  const [chatNB, setChatNB] = useState(0);
  useEffect(() => setChatNB(props.chatNB), [props.chatNB]);
  return (
    <React.Fragment>
      <div className="side-menu flex-lg-column mr-lg-1">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <Link to="#" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="logo" height="30" />
            </span>
          </Link>

          <Link to="#" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="logo" height="30" />
            </span>
          </Link>
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className="flex-lg-column my-auto">
          <Nav
            pills
            className="side-menu-nav justify-content-center"
            role="tablist"
          >
            <NavItem id="profile">
              <NavLink
                id="pills-user-tab"
                className={classnames({ active: activeTab === "profile" })}
                onClick={() => {
                  toggleTab("profile");
                }}
              >
                <i className="ri-user-2-line"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="profile" placement="top">
              個案
            </UncontrolledTooltip>
            <NavItem id="Chats">
              <NavLink
                id="pills-chat-tab"
                className={classnames({ active: activeTab === "chat" })}
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className="ri-message-3-line"></i>
                {chatNB > 0 && <span className="popCount">{chatNB}</span>}
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Chats" placement="top">
              討論區
            </UncontrolledTooltip>
            <NavItem id="Notify">
              <NavLink
                id="pills-setting-tab"
                className={classnames({ active: activeTab === "notify" })}
                onClick={() => {
                  toggleTab("notify");
                }}
              >
                <i className="ri-notification-line"></i>
                {notificationNB > 0 && (
                  <span className="popCount">{notificationNB}</span>
                )}
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Notify" placement="top">
              通知
            </UncontrolledTooltip>
            <NavItem id="Contacts">
              <NavLink
                id="pills-contacts-tab"
                className={classnames({ active: activeTab === "contacts" })}
                onClick={() => {
                  toggleTab("contacts");
                }}
              >
                <i className="ri-contacts-line"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Contacts" placement="top">
              通訊錄
            </UncontrolledTooltip>


            <NavItem id="Import">
              <NavLink
                id="pills-import-tab"
                className={classnames({ active: activeTab === "import" })}
                onClick={() => {
                  toggleTab("import");
                }}
              >
              <i className="ri-folder-user-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Import" placement="top">
              匯入個案
            </UncontrolledTooltip>

            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className="profile-user-dropdown d-inline-block d-lg-none"
            >
              <DropdownToggle nav>
                <img
                  src={
                    !!props.userInfo.image
                      ? SourceURL + props.userInfo.image
                      : defaultImg
                  }
                  alt=""
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/#/logout">
                  登出{" "}
                  <i className="ri-logout-circle-r-line float-right text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        <div className="flex-lg-column d-none d-lg-block">
          <Nav className="side-menu-nav justify-content-center">
            <Dropdown
              nav
              isOpen={dropdownOpen}
              className="btn-group dropup profile-user-dropdown"
              toggle={toggle}
            >
              <DropdownToggle nav>
                <img
                  src={logoutImg}
                  alt=""
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/#/logout">
                  登出{" "}
                  <i className="ri-logout-circle-r-line float-right text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* Side menu user */}
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
