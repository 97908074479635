export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const ACTIVE_USER_TEMP = "ACTIVE_USER_TEMP";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const SET_CASE_LIST = "SET_CASE_LIST";
export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_MEMBER_INFO = "SET_MEMBER_INFO";
export const SET_MESSAGE_KEYWORD = "SET_MESSAGE_KEYWORD";
export const SET_LOAD_MESSAGE = "SET_LOAD_MESSAGE";
export const SET_HELLOWORLD = "SET_HELLOWORLD";

